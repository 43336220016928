/* eslint-disable no-unused-vars */
/* global $, bootstrap, YT, AOS */

import './header';
import './carousels';
import './menu_sidebar';
import './modal';
import './video';

window.addEventListener('load', () => {
  AOS.init({
    easing: 'ease-in-out',
    once: true,
    duration: 1000,
    disable: 'mobile',
  });

  // Activate popovers
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]:not(.figure)'));
  popoverTriggerList.map((popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl));

  // Activate Tooltips
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]:not(.js-region)'));
  tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));

  $('select[js-select="dpto-tipo"]').change((evt) => {
    const current = evt.currentTarget;
    $('select[js-select="dpto-tipo"]').val($(current).val());
    $('#data-dpto').show();
  });

  $('select[js-select="piso"]').change((evt) => {
    const current = evt.currentTarget;
    $('select[js-select="piso"]').val($(current).val());
    $('#data-dpto').show();
  });

  $('#form-planes select[js-select="piso"], #form-planes select[js-select="piso"]').change(() => {
    if ($('#data-dpto').is(':hidden')) {
      $('#data-dpto').show();
    }
  });

  $('#form-banner input[name="tipo-depa"], #form-planes input[name="tipo-depa"]').click((evt) => {
    const current = evt.currentTarget;
    $(`input[id="${current.id}"]`).prop('checked', true);
  });

  $('#range-years').on('input', (evt) => {
    const current = evt.currentTarget;
    $('#years').text(`${$(current).val()} años`);
  });

  $('#proyecta-cuotas').click((evt) => {
    evt.preventDefault();
    $('#cuotas').slideDown();
    $('#proyecta-cuotas').fadeOut();
  });
});
