/* global $, videojs */

window.addEventListener('load', () => {
  if ($('#my-player').length > 0) {
    const player = videojs('my-player');
    const modals = $('.modal');

    // modals.on('show.bs.modal', () => {
    //   player.play();
    // });

    modals.on('hidden.bs.modal', () => {
      player.pause();
    });
  }
});
